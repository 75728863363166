// CataloguesData.js
import catalogueImage1 from "../assets/img/fournisseurs/LEGARND.png";
import catalogueImage2 from "../assets/img/fournisseurs/UNIFORMATIC.png";
import catalogueImage3 from "../assets/img/fournisseurs/COMUNELLO.png";
import catalogueImage4 from "../assets/img/fournisseurs/BASOR.png";
import catalogueImage5 from "../assets/img/fournisseurs/Dahua.png";
import catalogueImage6 from "../assets/img/fournisseurs/SPIT.png";
import catalogueImage7 from "../assets/img/fournisseurs/VTAC.png";
import catalogueImage8 from "../assets/img/fournisseurs/Ajax.png";
import catalogueImage9 from "../assets/img/fournisseurs/Schneider.png";
import catalogueImage10 from "../assets/img/fournisseurs/B.E.G.png";
import catalogueImage11 from "../assets/img/fournisseurs/BLM.png";
import catalogueImage12 from "../assets/img/fournisseurs/Total-Tools.png";
import catalogueImage13 from "../assets/img/fournisseurs/Delta-Dore.png";
import catalogueImage14 from "../assets/img/fournisseurs/Gewiss-logo.png";
// ... (import other images) ...

const Url1 = 'https://www.legrand.fr/pro';
const Url2 = "https://www.uniformatic.fr/";
const Url3 = "https://www.comunello.com/";
const Url4 = "https://www.basor.com/";
const Url5 = "https://www.dahuasecurity.com/fr";
const Url6 = "https://www.spitpaslode.fr/fr/";
const Url7 = "https://v-tac.eu/";
const Url8 = "https://ajax.systems/fr/";
const Url9 = "https://www.se.com/ww/fr/";
const Url10 = "https://www.beg-luxomat.com/fr/";
const Url11 = "https://www.blmd.fr/";
const Url12 = "https://www.totalbusiness.com/";
const Url13 = "https://www.deltadore.fr/";
const Url14 = "https://www.gewiss.com/fr/fr/";

// Add other URL constants for other catalogue items

const partenairesData = [
  {
    id: 1,
    image: catalogueImage1,
    url: Url1,
    alt: "Catalogue Legrand",
  },
  {
    id: 2,
    image: catalogueImage2,
    url: Url2,
    alt: "Catalogue Uniformatic",
  },
  {
    id: 3,
    image: catalogueImage3,
    url: Url3,
    alt: "Catalogue Comunello",
  },
  {
    id: 4,
    image: catalogueImage4,
    url: Url4,
    alt: "Catalogue Basor",
  },
  {
    id: 5,
    image: catalogueImage5,
    url: Url5,
    alt: "Catalogue Dahua",
  },
  {
    id: 6,
    image: catalogueImage6,
    url: Url6,
    alt: "Catalogue Spit",
  },
  {
    id: 7,
    image: catalogueImage7,
    url: Url7,
    alt: "Catalogue V-TAC",
  },
  {
    id: 8,
    image: catalogueImage8,
    url: Url8,
    alt: "Catalogue Ajax",
  },
  {
    id: 9,
    image: catalogueImage9,
    url: Url9,
    alt: "Catalogue Schneider",
  },
  {
    id: 10,
    image: catalogueImage10,
    url: Url10,
    alt: "Catalogue V-TAC Solar",
  },
  {
    id: 11,
    image: catalogueImage11,
    url: Url11,
    alt: "Catalogue BLM",
  },
  {
    id: 12,
    image: catalogueImage12,
    url: Url12,
    alt: "Catalogue BEG",
  },
  {
    id: 13,
    image: catalogueImage13,
    url: Url13,
    alt: "Catalogue DELTA Dore",
  },
  {
    id: 14,
    image: catalogueImage14,
    url: Url14,
    alt: "Gewiss France",
  },

  // ... (add other catalogue items) ...
];

export default partenairesData;
