// CataloguesData.js
import catalogueImage1 from "../assets/img/Catalogues/C_Legrend.png";
import catalogueImage2 from "../assets/img/Catalogues/-Page-001.jpg";
import catalogueImage3 from "../assets/img/Catalogues/C_COMUNELLO.png";
import catalogueImage4 from "../assets/img/Catalogues/C_Basor.png";
import catalogueImage5 from "../assets/img/Catalogues/C_Dahua.png";
import catalogueImage6 from "../assets/img/Catalogues/C_Spit.png";
import catalogueImage7 from "../assets/img/Catalogues/C_VTAC.png";
import catalogueImage8 from "../assets/img/Catalogues/C_Ajax2.png";
import catalogueImage9 from "../assets/img/Catalogues/C_Schneider.png";
import catalogueImage10 from "../assets/img/Catalogues/V-TAC-Solar.jpg";
import catalogueImage11 from "../assets/img/Catalogues/Catalogue_BLM.jpg.png";
import catalogueImage12 from "../assets/img/Catalogues/Catalogue_BEG.png";
import catalogueImage14 from "../assets/img/Catalogues/1.jpg";
import catalogueImage13 from "../assets/img/Catalogues/C_DeltDor.png";
import catalogueImage15 from "../assets/img/Catalogues/C_Gewiss-Mavil.png"
// ... (import other images) ...

const Url1 = 'https://documents.mondistri.re/legrand-catalogue-france.pdf';
const Url2 = "https://documents.mondistri.re/CATALOGUE_UNIFORMATIC_VDI_2023_HD.pdf";
const Url3 = "https://documents.mondistri.re/c_comunelo_2023";
const Url4 = "https://documents.mondistri.re/BASOR-catalogue.pdf";
const Url5 = "https://documents.mondistri.re/Dahua-France-Catalogue.pdf";
const Url6 = "https://fr.calameo.com/read/006570162c531af615c0c?page=1";
const Url7 = "https://gheledpro.fr/catalogue/v-tac/";
const Url8 = "https://online.fliphtml5.com/jcvvc/kgaf/";
const Url9 = "https://www.document.schneider-electric.fr/catalog/schneider-electric-avril-2023/";
const Url10 = "http://documents.mondistri.re/V-TAC_Solar_Energy-Catalog.pdf";
const Url11 = "https://www.calameo.com/read/002996691cbfadc263a9e";
const Url12 = "https://www.beg-luxomat.com/katalog/index.php?lang=FR&katalog=Catalogue_detecteurs_de_presence_et_de_mouvement";
const Url14 = "https://documents.mondistri.re/c_total-tools_2023";
const Url13 = "https://documents.mondistri.re/c_delta-dore_2023";
const Url15 = "https://documents.mondistri.re/c_gewiss-mavil_2023-2024";
const Download1 = "https://documents.mondistri.re/legrand-catalogue-france.pdf"
// Add other URL constants for other catalogue items

const catalogueData = [
  {
    id: 1,
    image: catalogueImage1,
    url: Url1,
    download: Download1,
    alt: "Catalogue Legrand",
  },
  {
    id: 2,
    image: catalogueImage2,
    url: Url2,
    alt: "Catalogue Uniformatic",
  },
  {
    id: 3,
    image: catalogueImage3,
    url: Url3,
    alt: "Catalogue Comunello",
  },
  {
    id: 4,
    image: catalogueImage4,
    url: Url4,
    alt: "Catalogue Basor",
  },
  {
    id: 5,
    image: catalogueImage5,
    url: Url5,
    alt: "Catalogue Dahua",
  },
  {
    id: 6,
    image: catalogueImage6,
    url: Url6,
    alt: "Catalogue Spit",
  },
  {
    id: 7,
    image: catalogueImage7,
    url: Url7,
    alt: "Catalogue V-TAC",
  },
  {
    id: 8,
    image: catalogueImage8,
    url: Url8,
    alt: "Catalogue Ajax",
  },
  {
    id: 9,
    image: catalogueImage9,
    url: Url9,
    alt: "Catalogue Schneider",
  },
  {
    id: 10,
    image: catalogueImage10,
    url: Url10,
    alt: "Catalogue V-TAC Solar",
  },
  {
    id: 11,
    image: catalogueImage11,
    url: Url11,
    alt: "Catalogue BLM",
  },
  {
    id: 12,
    image: catalogueImage12,
    url: Url12,
    alt: "Catalogue BEG",
  },
  {
    id: 13,
    image: catalogueImage13,
    url: Url13,
    alt: "Catalogue DELTA Dore",
  },
  {
    id: 14,
    image: catalogueImage14,
    url: Url14,
    alt: "Catalogue Total-Tools",
  },
  {
    id: 15,
    image: catalogueImage15,
    url: Url15,
    alt: "Catalogue Gewiss_Mavil-2023/2024",
  },
  // ... (add other catalogue items) ...
];

export default catalogueData;
